/* You can add global styles to this file, and also import other style files */
body,
p,
b,
a,
h1,
h2,
h3,
h4,
h5,
h6,
span,
button,
div,
table,
tr,
th,
td {
  font-family: 'Raleway' !important;
}

@import url(theme.scss);
@import "~@ng-select/ng-select/themes/default.theme.css";
@import url('https://fonts.googleapis.com/css2?family=PT+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html {
  scroll-behavior: smooth;
}

.ng-select.ng-select-single .ng-select-container {
  height: 53px;
  border-radius: 8px 8px !important;
  
}
.numbers{
  font-family: 'inter' !important;
}

select {
  outline: none !important;
}

a,
button,
button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.cursorPointer {
  cursor: pointer;
}

.bgf0f1f5 {
  background-color: #f0f1f5;
}

.primaryBtn {
  background-color: #ffffff;
  color: #8d0528;
  font-weight: 600;
  border: 1px solid #8d0528;
}

.primaryBtn:hover {
  background-color: #8d0528;
  color: #ffffff;
}

.consultationbtn {
  padding: 8px 23px;
  color: #ffffff;
  border: solid 1px #8d0528;
  background-color: #8d0528;
  border-radius: 9px 9px 9px 9px !important;
}

.consultationbtn:hover {
  color: #8d0528;
  background-color: #ffffff;
}

.consultbtn {
  width: 153px;
  height: 48px;
  border: solid 1px #8d0528;
  background-color: #8d0528;
  color: white;
  background-color: #8D0528;
  text-transform: none !important;
  border-radius: 9px 9px 9px 9px !important;
  margin-left: 0px;
  font-family: "Arimo", sans-serif
}

.consultbtn:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
}

.how-it-works {
  position: absolute;
  top: 24px;
  left: 56px;
  color: #ffffff
}

.mby_img116 {
  display: flex;
  justify-content: center;
  padding-top: 28px;
}

.consultbtn:hover {
  box-shadow: 0 12px 16px 0 rgba(0, 0, 0, 0.24), 0 17px 50px 0 rgba(0, 0, 0, 0.19);
  background-color: #ffffff;
}

.how-it-works {
  position: absolute;
  top: 24px;
  left: 56px;
  color: #ffffff
}

.mby_img116 {
  display: flex;
  justify-content: center;
  padding-top: 28px;
}

// Theme style
.br_5 {
  border-radius: 5px !important;
}

.br_10 {
  border-radius: 10px !important;
}

.p12px {
  padding: 12px;
}

.mt50px {
  margin-top: 50px;
}

.pb50px {
  padding-bottom: 50px;
}

.bg_black {
  background-color: black !important;
}

.bg_8d0528 {
  background-color: #8d0528 !important;
}

.zI1 {
  z-index: 1;
}

.titleTriangleinclusion {
  background-color: #8d0528;
  padding: 12px 10px 6px 18px;
  position: relative;
  font-size: 18px;
  color: #ffffff;
  line-height: 13px;
}

.titleTriangle {
  background-color: #8d0528;
  padding: 6px 4rem 4px 2rem;
  position: relative;
  font-size: 18px;
  color: #ffffff;
  line-height: 28px;
}

.titleTriangle1 {
  background-color: #8d0528;
  padding: 6px 1rem 4px 2rem;
  position: relative;
  font-size: 18px;
  color: #ffffff;
  line-height: 28px;
}

.titleTriangletheme {
  background-color: #8d0528;
  padding: 6px 1rem 4px 2rem;
  position: relative;
  font-size: 18px;
  color: #ffffff;
  line-height: 28px;
}

.titleTriangle .mask {
  position: absolute;
  height: 4rem;
  background-color: #ffffff;
  width: 6rem;
  top: 0.6rem;
  right: -3.6rem;
  transform: rotate(120deg);
}

.titleTriangle2 {
  background-color: #8d0528;
  padding: 6px 4rem 4px 2rem;
  position: relative;
  font-size: 17px;
  color: #ffffff;
  line-height: 28px;
}

.titleTriangle .mask {
  position: absolute;
  height: 4rem;
  background-color: #ffffff;
  width: 6rem;
  top: 0.6rem;
  right: -3.6rem;
  transform: rotate(120deg);
}

.smallLogoIcon {
  width: 40px;
  margin-left: -4px;
}

.ngx-pagination li,
.ngx-pagination li.pagination-next.disabled,
.ngx-pagination li.pagination-previous.disabled {
  background-color: #ffffff !important;
  color: #cccccc !important;
  border: 2px solid #cccccc !important;
  margin: 0 4px !important;
}

.ngx-pagination .current {
  background-color: #ffffff !important;
  color: #000000 !important;
  border: 2px solid #000000 !important;
}

.ngx-pagination li.pagination-next,
.ngx-pagination li.pagination-previous {
  color: #8d0528 !important;
  border: 2px solid #8d0528 !important;
}

.ngx-pagination {
  padding-left: 0px !important;
}

@media only screen and (max-width: 600px) {

  .titleTriangleworks {
    background-color: #8d0528 !important;
    padding: 12px 20px 6px 20px !important;
    position: relative !important;
    font-size: 14px !important;
    color: #ffffff !important;
    line-height: 13px !important;
  }

  .ngx-pagination li,
  .ngx-pagination li.pagination-next.disabled,
  .ngx-pagination li.pagination-previous.disabled {
    background-color: #ffffff !important;
    color: #cccccc !important;
    border: 2px solid #cccccc !important;
    margin: 0 1px !important;
  }

  .ngx-pagination .disabled {
    padding: 0.175rem 0.125rem !important;
    color: #cacaca;
    cursor: default !important;
  }


}

//// Theme style
.modal-open {
  padding-right: 0px !important;
}

@media screen and (max-width: 600px) {
  .titleTriangleinclusion {
    background-color: #8d0528;
    padding: 12px 10px 6px 18px;
    position: relative;
    font-size: 14px;
    color: #ffffff;
    line-height: 13px;
  }

  .titleTriangle {
    background-color: #8d0528;
    padding: 12px 10px 6px 23px;
    position: relative;
    font-size: 14px;
    color: #ffffff;
    line-height: 13px;
  }

  .titleTriangle1 {
    background-color: #8d0528;
    padding: 12px 10px 6px 23px;
    position: relative;
    font-size: 14px;
    color: #ffffff;
    line-height: 13px;
  }

  // .titleTriangle1 {
  //   background-color: #8d0528;
  //   padding: 12px 10px 6px 23px;
  //   position: relative;
  //   font-size: 13px;
  //   color: #ffffff;
  //   line-height: 13px;
  // }
  .titleTriangletheme {
    background-color: #8d0528;
    padding: 12px 10px 6px 12px;
    position: relative;
    font-size: 13px;
    color: #ffffff;
    line-height: 13px;
  }

  .titlerow {
    justify-content: center !important;
  }
}

#pano {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.sign-in-style {
  display: none !important;
}

.pb22px {
  padding-bottom: 22px;
}

.mt22px {
  margin-top: 22px;
}

.href_text {
  text-decoration: none !important;
  color: #ffffff !important;
}

.href1_text {
  text-decoration: none !important;
  color: #000000 !important;
}

.titleTriangleworks {
  background-color: #8d0528;
  padding: 5px 2rem 4px 3rem;
  position: relative;
  font-size: 18px;
  color: #ffffff;
  line-height: 28px;
}

.transparent-modal {
  .modal-content {
    background-color: unset;
    border: unset;
  }
}
.pac-container{  
    z-index: 20000 !important;
    border: 2px solid;
}
.pac-container{
  z-index: 6666 !important;
}
/* datepicker css */

.ui-datepicker {
  text-align: center;
}

.ui-datepicker-trigger {
  margin: 0 0 0 5px;
  vertical-align: text-top;
}

.ui-datepicker {
  font-family: 'PT Sans', "Arimo", sans-serif !important;
  margin-top: 2px;
  padding: 0 !important;
  border-color: #c9f0f5 !important;
}

.ui-datepicker {
  width: 256px;
}

.openemr-calendar .ui-datepicker {
  width: 191px;
}

.ui-datepicker table {
  width: 256px;
  table-layout: fixed;
}

.openemr-calendar .ui-datepicker table {
  width: 191px;
  table-layout: fixed;
}

.ui-datepicker-header {
  background-color: #8D0528 !important;
  background-image: none !important;
  border-radius: 0;
  padding-top: 0px !important;
  padding-bottom: 6px !important;
}

.openemr-calendar .ui-datepicker-header {
  background-color: #e6f7f9 !important;
  border-width: 1px;
  border-color: #c9f0f5;
  border-style: solid;
}

.ui-datepicker-title {
  line-height: 35px !important;
  margin: 0 10px !important;
}

.openemr-calendar .ui-datepicker-title {
  line-height: 20px !important;
}

.ui-datepicker-prev span {
  display: none !important;
}

.ui-datepicker-next {
  text-align: center;

 
}

.ui-datepicker-next span {
  display: none !important;
}

.ui-datepicker-prev {
  background-color: transparent !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAcAAAAMCAYAAACulacQAAAAUklEQVQYlXWPwQnAMAwDj9IBOlpH8CjdJLNksuujFIJjC/w6WUioFBcqJ7sGEAD5Y/hpqLRghRv4YQlUjqXI3Kql2MixraGbEhVcDXcFUR/1egEHNuTBpFW0NgAAAABJRU5ErkJggg==') !important;
  height: 12px !important;
  width: 7px !important;
  margin: 14px 12px;
  display: inline-block;
  left: 0 !important;
  top: 3px !important;
}

.openemr-calendar .ui-datepicker-prev {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAkAAAAOCAYAAAD9lDaoAAAAuUlEQVQokXXRsUtCYRAA8J8pDQ1CVIgIgtBU2NDiZIuDS4uLf6WDS1O0tLREEE8icBNKS3lTs8/B78XHw3dwcHA/juOuqjzucYJVrQQMcYctvo4OgEFIeMK6iPphCjzjEWLUC3vACx7yRo5uMUIFr5gii1EL41AvMIkBVPGH04DrSLEsIvjEOZq4wi9+iijDR0ANXOMbmxjlcIY2LtANO6YxymGCDs5wg/ciYv+KBJeY4+2A+Y9j4Y47RtUkrNXeDxUAAAAASUVORK5CYII=') !important;
  height: 14px !important;
  width: 9px !important;
  margin: 5px !important;
}

.ui-datepicker-next {
  cursor: pointer;
}

.ui-datepicker-prev {
  cursor: pointer;
}

.ui-datepicker-next {
  background-color: transparent !important;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAMCAYAAABfnvydAAAAVUlEQVQYlXWQ0Q3AIAhEL07gKI7kKN2kI3Wk1w9to3KQEELucQEECOizhhTQGHFnwOdgobWx0GkZILfYBhXl0STVbPoBarbkL7ozN/F8VBBXh8uJgF5r2hrI4GHUkAAAAABJRU5ErkJggg==') !important;
  height: 12px !important;
  width: 8px !important;
  margin: 14px 12px;
  display: inline-block;
  right: 0 !important;
  top: 3px !important;
}

.openemr-calendar .ui-datepicker-next {
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAgAAAAOCAYAAAASVl2WAAAAtElEQVQYlXXQsUpCcRQH4I97EQyHa1pgIEE0hBGYL+BjNLRFjxXh4rM4F21BS4S4FAgqQioOngt/RM/6+zi/w4EanlA4MDkecYsO3vG/D8a4Rx03eMMqBQt8oodTXAdalwBm+IpNDVxG3aYEMMU3ujjDBT5SAH9R2cE58mwPFOgneJSCGp7RjLoXTEtQjbCFOV7xCxkqdp9sYxnhpFyb4QFXdh8c4Cc9Ko++OwzjFwfn5FiwBVeuI/K2UCkSAAAAAElFTkSuQmCC') !important;
  height: 14px !important;
  width: 8px !important;
  margin: 5px;
}

.ui-datepicker-month {
  border-radius: 2px;
  width: 110px !important;
  height: 22px;
  font-family: 'PT Sans', "Arimo", sans-serif !important;
  color: #fff;
  font-size: 14px !important;
  font-weight: 600;
  text-align: left;
  border: none !important;
  vertical-align: text-top;
}

.openemr-calendar .ui-datepicker-month {
  font-family: 'PT Sans', "Arimo", sans-serif !important;
  color: rgba(34, 34, 34, 0.87);
  font-size: 12px !important;
  font-weight: 700;
  text-align: center;
  transform: scaleX(1.0029)
}

.ui-datepicker-year {
  border-radius: 2px;
  width: 61px !important;
  height: 22px;
  border: none !important;
  font-family: Open Sans !important;
  color: #fff;
  font-size: 14px !important;
  font-weight: 600;
  text-align: left;
  vertical-align: text-top;
}

.openemr-calendar .ui-datepicker-year {
  font-family: 'PT Sans', "Arimo", sans-serif !important;
  color: rgba(34, 34, 34, 0.87);
  font-size: 12px !important;
  font-weight: 700;
  text-align: center;
  transform: scaleX(1.0029)
}

.ui-datepicker-month option,
.ui-datepicker-year option {
  color: #3985a0 !important;
  background-color: #fff !important;
  font-family: 'PT Sans', "Arimo", sans-serif !important;
  font-size: 14px !important;
  font-weight: 600;
}

.ui-datepicker-month option[selected],
.ui-datepicker-year option[selected] {
  background-color: #e5edf0 !important;
}

.ui-datepicker .ui-state-hover {
  /*background: none !important;*/
  border: 0 !important;
}

.ui-datepicker td {
  vertical-align: top;
}

.ui-datepicker .ui-state-default {
  border-radius: 2px;
  border-color: #edebeb !important;
/*     background: white !important; */
  width: 24px;
  height: 24px;
  padding: 0 !important;
  line-height: 24px;
  text-align: center !important;
  font-family: 'PT Sans', "Arimo", sans-serif !important;
  color: #707070;
  font-size: 13px;
  font-weight: 400 !important;
  margin: 7px 0 0 4px;
}

.ui-datepicker .ui-state-default{
  border-color: #dcdcdc;
  background-color: #0000 !important;
  color: #000 !important;
}

.openemr-calendar .ui-state-default {
  font-size: 10px;
  margin: 0;
}

.ui-datepicker td {
  width: 33px;
}

.openemr-calendar .ui-datepicker td {
  width: 26px;
}

.openemr-calendar .ui-state-default {
  width: 26px;
  height: 20px;
  line-height: 20px;
}
.ui-state-default.ui-state-hover {
  border-color: #8D0528;
  background-color: #8D0528 !important;
}

.ui-datepicker .ui-state-active {
  border-color: #8D0528;
  background-color: #8D0528 !important;
  color: #fff !important;
} 

.ui-datepicker-calendar thead tr th {
  font-family: 'PT Sans', "Arimo", sans-serif !important;
  color: #8D0528;
  font-size: 12px;
  font-weight: 400;
  padding: 0.45em 0.3em !important;
  /*   width: 15px !important; */
}

.openemr-calendar .ui-datepicker-calendar thead tr th {
  font-size: 10px;
}

.ui-datepicker-close {
  display: none;
}

.ui-datepicker thead {
  background-color: #f5f5f5;
}

.openemr-calendar .ui-datepicker thead {
  background: none;
}

.ui-state-default.ui-datepicker-current {
  float: none !important;
  font-family: 'PT Sans', "Arimo", sans-serif !important;
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  border-width: 0 !important;
  border: none;
  vertical-align: top;
  margin: 0 !important;
  background-color: transparent !important;
}

.ui-datepicker-buttonpane.ui-widget-content {
  text-align: center;
  background-color: #8D0528;
  margin: 0 !important;
  height: 28px;
  padding: 0 !important;
}

.openemr-calendar .ui-datepicker-year {
  background-color: transparent;
}

.openemr-calendar .ui-datepicker-month {
  background-color: transparent;
}

.openemr-calendar .ui-state-default {
  border: 0 !important;
}

.openemr-calendar .ui-datepicker-month {
  margin-right: 10px !important;
}
.ui-datepicker-unselectable span{
  border: 2px solid red;
}
.ui-datepicker table {
  width: 100% !important;
  table-layout: fixed;
}
.ui-datepicker {
  width: 100% !important;
}
.ui-state-highlight{
  background-color: yellow !important;
}
.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #dad55e !important;
  background: #fffa90 !important;
  color: #1d1c1c !important;
}
.ui-datepicker-today .ui-state-default{
  background-color: #ffdf00 !important;
  color: #171515 !important;
}
p{
  letter-spacing: .5px !important;
  line-height: 150% !important;
  font-weight: 300 !important
}
.button{
font-family: Raleway !important;
font-weight: 600;
font-size: 13px;
line-height: 105%;
border-radius: 25px;
border: none;
padding: 7px 17px;
width: -moz-fit-content;
width: fit-content;
}
.btn-pink{
  background: #8d0528;
  color: #fff;
}

.loading {
  position: fixed;
  z-index: 999;
  height: 6px;
  width: 6em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #ffffff;
  opacity: 0.97;
}

.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  // background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
  // background: -webkit-radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));
}