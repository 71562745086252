:root{
    --primary-color:#8D0627;
    --secondary-background: #F5F5F5;





    --primary-text: #000000;
    --light-text: #F5F5F5;




}